import { Component } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss',
})
export class HomePage {
  openMenu() {
    window.open('assets/menu.pdf');
  }
}
