<div
  class="w-screen h-screen flex flex-column bg-white justify-content-center align-items-center"
>
  <img src="assets/logo.svg" class="h-6rem fadein animation-duration-1000" />

  <div class="mt-6 text-center fadein animation-duration-1000">
    <span class="text-primary"
      >Presto online l'esperienza digitale di Terrazza San Michele</span
    >
  </div>

  <div
    class="border-blink mt-6 bg-white h-4rem w-8 border-round-xl flex flex-column justify-content-center align-items-center"
    (click)="openMenu();"
  >
    <span class="text-primary">Menù</span>
  </div>

  <div
    class="mt-8 flex flex-column justify-content-center align-items-center text-center"
  >
    <a class="text-primary" href="tel:+393511290438">+39 3511290438</a>
    <a
      class="text-primary"
      href="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=/maps/place//data%3D!4m2!3m1!1s0x133baf184f8fc077:0xc1663e5c33483ab0%3Fsa%3DX%26ved%3D1t:8290%26ictx%3D111&ved=2ahUKEwjb7J2_pcyGAxW6iP0HHcXRCOUQ4kB6BAhPEAM&usg=AOvVaw13-3CpLKrGB7F9SRB8foKl"
      >Vico III S. Michele, 15 - Ottaviano (NA)</a
    >
  </div>
</div>
