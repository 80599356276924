import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import itLocale from 'primelocale/it.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(itLocale.it);
  }
}
